/**
 * packageName    : mb.utils
 * fileName       : mbUtils
 * author         : ksj
 * date           : 2023-06-14
 * description    : 회원파트 공통 utils
 * =============================================================
 * DATE             AUTHOR          NOTE
 * -------------------------------------------------------------
 * 2023-06-14      ksj       최초생성
 */

const mbUtils = {
  methods: {
    // 우편번호 검색
    addrSearch (thisData, formName) {
      // eslint-disable-next-line no-undef
      new daum.Postcode({
        oncomplete: (data) => {
          console.log(data)
          // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

          // 도로명 주소의 노출 규칙에 따라 주소를 표시한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          let roadAddr = data.roadAddress || data.autoRoadAddress // 도로명 주소 변수
          let extraRoadAddr = '' // 참고 항목 변수

          // 법정동명이 있을 경우 추가한다. (법정리는 제외)
          // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
          if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
            extraRoadAddr += data.bname
          }

          // 건물명이 있는경우 빌딩주소를 도로명 주소에 추가 한다.
          if (data.buildingName !== '') {
            roadAddr += ' (' + data.buildingName + ')'
          }

          // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
          if (extraRoadAddr !== '') {
            extraRoadAddr = ' (' + extraRoadAddr + ')'
          }

          // return addrArr
          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          thisData[formName].addrZip = data.zonecode
          thisData[formName].addrBassEaddr = roadAddr
        }
      }).open()
    },

    chgBooleanYn (val) {
      if (val === undefined) {
        return 'N'
      }
      if (typeof val === 'boolean') {
        if (val) {
          return 'Y'
        } else {
          return 'N'
        }
      } else if (typeof val === 'string') {
        if (val === 'Y') {
          return true
        } else if (val === 'N') {
          return false
        }
      }
    },

    empnoFormat (phoneNumber) {
      if (!phoneNumber) { return phoneNumber }
      phoneNumber = phoneNumber.replace(/[^0-9]/g, '')

      let res = ''
      if (phoneNumber.length < 3) {
        res = phoneNumber
      } else if (phoneNumber.substr(0, 2) === '02') {
        if (phoneNumber.length <= 5) { // 02-123-5678
          res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 3)
        } else if (phoneNumber.length > 5 && phoneNumber.length <= 9) { // 02-123-5678
          res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 3) + '-' + phoneNumber.substr(5)
        } else if (phoneNumber.length > 9) { // 02-1234-5678
          res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 4) + '-' + phoneNumber.substr(6)
        }
      } else if (phoneNumber.length < 8) {
        res = phoneNumber
      } else if (phoneNumber.length === 8) {
        res = phoneNumber.substr(0, 4) + '-' + phoneNumber.substr(4)
      } else if (phoneNumber.length === 9) {
        res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6)
      } else if (phoneNumber.length === 10) {
        res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6)
      } else if (phoneNumber.length > 10) { // 010-1234-5678
        res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 4) + '-' + phoneNumber.substr(7)
      }

      return res
    },

    // CD_00152 01본인인증
    // CD_00152 02미성년자본인아이핀인증
    getMbty (userTy) {
      if (userTy === '10' || userTy === '22' || userTy === '30' || userTy === '40') {
        return '01'
      } else if (userTy === '21') {
        return '02'
      }
    },
    // CD_00190 1 개인
    // CD_00190 2 기업
    // CD_00190 0 전체
    getMbty2 (userTy) {
      if (userTy === '10' || userTy === '21' || userTy === '22' || userTy === '30') {
        return '1'
      } else if (userTy === '40') {
        return '2'
      }
    },

    // 값이 있을때에만 assign
    assignNotNull (target, source) {
      // FormData의 key 확인
      for (const key of Object.keys(source)) {
        if (source[key] !== undefined && source[key] !== '' && source[key] !== null) {
          target[key] = source[key]
        }
      }
      return target
    },

    getSnsTyNm (snsTy) {
      if (snsTy === 'A') {
        return '카카오'
      } else if (snsTy === 'B') {
        return '네이버'
      } else if (snsTy === 'C') {
        return '구글'
      } else if (snsTy === 'D') {
        return '애플'
      }
    },

    getSnsTy (snsTy) {
      if (snsTy === 'A') {
        return 'kakao'
      } else if (snsTy === 'B') {
        return 'naver'
      } else if (snsTy === 'C') {
        return 'google'
      } else if (snsTy === 'D') {
        return 'apple'
      }
    },

    openToast (msg, dur) {
      this.$notify({
        message: msg,
        position: 'bottom-left',
        showClose: false,
        duration: dur || 3000,
        onClick: () => { this.$notify.closeAll() }
      })
    }
  }
}

export default mbUtils
